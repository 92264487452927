import React from "react";
import "./style.css";

export default function ButtonComponents({
  children,
  onClick,
  type = "button",
  disabled = false,
  className = "",
  style,
  ...props
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      style={style}
      className={`btnStyle ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}
