// Library imports
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

// Local imports
import { NavItems } from "../../data/header";

export const NavBar = () => {
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: "home", element: document.getElementById("home") },
        { id: "games", element: document.getElementById("games") },
        { id: "about", element: document.getElementById("about") },
        { id: "contact", element: document.getElementById("contact") },
      ];
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const scrollBottom = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      let active = "home";

      if (scrollBottom >= documentHeight - 1) {
        active = "contact";
      } else {
        for (const section of sections) {
          if (section.element) {
            const sectionTop = section.element.offsetTop;
            const sectionBottom = sectionTop + section.element.offsetHeight;
            if (
              scrollPosition >= sectionTop &&
              scrollPosition <= sectionBottom
            ) {
              active = section.id;
              break;
            }
          }
        }
      }
      setActiveSection(active);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="mx-auto flex decoration-blue-600 text-white max-sm:hidden max-md:space-x-6 md:space-x-8 2xl:space-x-10">
      {NavItems.map((button, index) => (
        <div
          key={index}
          className={`flex flex-col items-center text-[24px] ${
            activeSection === button.text.toLocaleLowerCase()
              ? "font-bold"
              : "font-regular"
          }`}
        >
          <NavLink to={button.path}>{button.text}</NavLink>
          <div
            className={`hover:cursor-pointer rounded-full border-b-4 w-[75%] ${
              activeSection === button.text.toLocaleLowerCase()
                ? "border-[#FAA51A]"
                : " border-[#00000000]"
            }`}
          />
        </div>
      ))}
    </nav>
  );
};
