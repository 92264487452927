// Library imports
import React, { useEffect, useRef, useContext, useState } from "react"
import "./Home.css"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useMediaQuery } from "react-responsive"
import { RiNotification2Fill } from "react-icons/ri"
import { GrNotification } from "react-icons/gr"
import { FaAngleLeft, FaAngleRight, FaQuoteLeft } from "react-icons/fa6"
import { GoPlus } from "react-icons/go"
import { FaPlay } from "react-icons/fa"
import { IoCheckmark } from "react-icons/io5"
// import { FaAngleLeft } from "react-icons/fa6";

// Local imports
import home_banner from "../../assets/images/home_banner.jpg"
import home_banner_mobile from "../../assets/pngs/home_Phone_banner.png"
import opod_play from "../../assets/pngs/opod_play.png"
import side_img from "../../assets/pngs/home_side_image.png"
import side_img2 from "../../assets/pngs/home_side_image2.png"
import game1 from "../../assets/pngs/first_game.png"
import game2 from "../../assets/pngs/second_game.png"
import game3 from "../../assets/pngs/third_game.png"
import down_green from "../../assets/pngs/down_green.png"
import down_yellow from "../../assets/pngs/down_yellow.png"
import down_red from "../../assets/pngs/down_red.png"
import quote from "../../assets/pngs/quote.png"
import free_label from "../../assets/pngs/free_label.png"
import cart from "../../assets/pngs/cart_black.png"
import { useLocation } from "react-router-dom"
import { DropdownContext } from "../../context/dropdown"
import { addToCart } from "../../redux/action/gamesCart"
import { useDispatch, useSelector } from "react-redux"
import logoImage from "../../assets/pngs/opod_play_logo.png"
import about_1 from "../../assets/pngs/about_1.png"
import about_2 from "../../assets/pngs/about_2.png"
import about_3 from "../../assets/pngs/about_3.png"
import opod_play_mobile from "../../assets/pngs/opod_play_mobile.png"
import Our_Mission from "../../assets/pngs/Our_Mission.png"
import Our_Vision from "../../assets/pngs/Our_Vision.png"
import The_Future from "../../assets/pngs/The_Future.png"
import { Footer } from "../../components/footer"
import DontMissOut from "../../components/Modals/DontMissOut"
import PrductPageModal from "../../components/Modals/PrductPageModal"
import ProductCheckoutModal from "../../components/Modals/ProductCheckoutModal"

import card_banner from "../../assets/webp/card_banner.jpg"
import BannerOne from "../../assets/webp/Banner_1.png"
import BannerTwo from "../../assets/webp/Banner_2.png"
import ComingSoon from "../../assets/pngs/coming_soon.png"
// import Cookies from "../../components/Modals/Cookies";
import { useNavigate } from "react-router-dom"

import "../../App.css"
import { getLocalCookie } from "../../utils/localstorage"
import ButtonComponents from "../../components/common/ButtonComponents"
import notify_bell from "../../assets/pngs/notify_bell.png"

export default function Home({ setIsHeaderShow, setIsPreOrderHeader }) {
  setIsHeaderShow(true)
  setIsPreOrderHeader(false)

  const [openDont, setOpenDont] = useState(false)
  // const [openCookies, setOpenCookies] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const [isAlreadyOpenNotify, setIsAlreadyOpenNotify] = useState(false)
  const [productPageModal, setProductPageModal] = useState({
    isOpen: false,
    game: {
      gameId: null,
      image: null,
      title: "",
      description1: "",
      description2: "",
    },
  })
  const getGamesData = useSelector((state) => state.cart.items)

  const { isDropdownOpen } = useContext(DropdownContext)
  const { setIsDropdownOpen } = useContext(DropdownContext)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const swipeRef = useRef(null)
  const homeRef = useRef(null)
  const gameSectionRef = useRef(null)
  const contactSectionRef = useRef(null)
  const aboutRef = useRef(null)
  const location = useLocation()

  const bannerData = [
    {
      id: 1,
      image: BannerOne,
      title: "S for Startup card game",
    },
    {
      id: 2,
      image: BannerTwo,
      title: "S for Startup card game",
    },
  ]

  // useEffect(() => {
  //   !!getLocalCookie() ? setOpenCookies(false) : setOpenCookies(true);
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  useEffect(() => {
    const hash = location.hash
    const offset = 10
    if (hash === "#home" && homeRef.current) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    } else if (hash === "#games" && gameSectionRef.current) {
      const elementPosition =
        gameSectionRef.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      })
    } else if (hash === "#about" && aboutRef.current) {
      const elementPosition =
        aboutRef.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      })
    } else if (hash === "#contact" && contactSectionRef.current) {
      const elementPosition =
        contactSectionRef.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      })
    }
  }, [location])

  useEffect(() => {
    if (!!isDropdownOpen && !!isMobile) {
      setIsDropdownOpen(false)
      navigate("/cart")
    }
  }, [isMobile, isDropdownOpen])

  useEffect(() => {
    if (productPageModal.isOpen) {
      setProductPageModal({
        ...productPageModal,
        game: {
          gameId: 1,
          image: game1,
          title: "S for Startup",
          description1: "Card Game | Medium Strategy | 2 - 4 Players",
          description2:
            "12 Years+, 108 cards, S for Startup card game is a quick way to learn about startups and entrepreneurs' journey, one decision at a time.",
          productDetail:
            "S for Startup card game is a fun and engaging card game that lets you unleash your inner entrepreneur. With 2-4 players and difficulty levels from easy to difficult, to choose from, you'll navigate the wild world of startups, tackling crazy ideas, securing funding with epic pitches, building your dream company, and soaring towards that coveted Unicorn status!  Every decision earns you Victory Points, so strategize, trade, and dodge those pesky power cards, to become the ultimate startup champion!  Whether you are playing with your kids or crushing it at a party, S for Startup card game is the perfect recipe for laughter, friendly competition, and a taste of the exciting startup life!",
        },
      })
    }
  }, [productPageModal.isOpen])

  const handleAddToCart = (game) => {
    dispatch(addToCart(game))
    if (isMobile) {
      navigate("/cart")
    } else {
      setIsDropdownOpen(true)
    }
  }

  const handleBannerStyle = (game) => {
    dispatch(addToCart(game))
    if (isMobile) {
      navigate("/checkout")
    } else {
      navigate("/preorder")
    }
  }

  const handlePreorder = (game) => {
    dispatch(addToCart(game))
    if (isMobile) {
      navigate("/checkout")
    } else {
      navigate("/preorder")
    }
  }

  const handleClick = () => {
    console.log("clicked")
    openProductPageModal()

    // Dynamically create a script element
    const scriptElement = document.createElement("script")
    scriptElement.type = "text/javascript"
    scriptElement.innerHTML = `
          (function () {
  var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: '66e35b-5e.myshopify.com',
      storefrontAccessToken: '7a672a59d64b9f620cf0c7c7611e992f',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('product', {
        id: '9664997884185',
        node: document.getElementById('product-component-1730223864376'),
        moneyFormat: 'Rs.%20%7B%7Bamount%7D%7D',
        options: {
  "product": {
    "styles": {
      "product": {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0",
          "margin-bottom": "50px"
        },
        "text-align": "left"
      },
      "title": {
        "font-size": "26px"
      },
      "button": {
        ":hover": {
          "background-color": "#253f1a"
        },
        "background-color": "#16250f",
        ":focus": {
          "background-color": "#253f1a"
        }
      },
      "price": {
        "font-size": "18px"
      },
      "compareAt": {
        "font-size": "15.299999999999999px"
      },
      "unitPrice": {
        "font-size": "15.299999999999999px"
      }
    },
    "buttonDestination": "checkout",
    "layout": "horizontal",
    "contents": {
      "img": false,
      "imgWithCarousel": true,
      "button": false,
      "buttonWithQuantity": true,
      "description": true
    },
    "width": "100%",
    "text": {
      "button": "Buy now"
    }
  },
  "productSet": {
    "styles": {
      "products": {
        "@media (min-width: 601px)": {
          "margin-left": "-20px"
        }
      }
    }
  },
  "modalProduct": {
    "contents": {
      "img": false,
      "imgWithCarousel": true,
      "button": false,
      "buttonWithQuantity": true
    },
    "styles": {
      "product": {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px"
        }
      },
      "button": {
        ":hover": {
          "background-color": "#253f1a"
        },
        "background-color": "#16250f",
        ":focus": {
          "background-color": "#253f1a"
        }
      },
      "title": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "bold",
        "font-size": "26px",
        "color": "#4c4c4c"
      },
      "price": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "normal",
        "font-size": "18px",
        "color": "#4c4c4c"
      },
      "compareAt": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "normal",
        "font-size": "15.299999999999999px",
        "color": "#4c4c4c"
      },
      "unitPrice": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "normal",
        "font-size": "15.299999999999999px",
        "color": "#4c4c4c"
      }
    },
    "text": {
      "button": "Add to cart"
    }
  },
  "option": {},
  "cart": {
    "styles": {
      "button": {
        ":hover": {
          "background-color": "#253f1a"
        },
        "background-color": "#16250f",
        ":focus": {
          "background-color": "#253f1a"
        }
      },
      "cart": {
        "background-color": "#f7f9ef"
      },
      "footer": {
        "background-color": "#f7f9ef"
      }
    },
    "text": {
      "total": "Subtotal",
      "button": "Checkout"
    },
    "popup": false
  },
  "toggle": {
    "styles": {
      "toggle": {
        "background-color": "#16250f",
        ":hover": {
          "background-color": "#253f1a"
        },
        ":focus": {
          "background-color": "#253f1a"
        }
      }
    }
  }
},
      });
    });
  }
})();
        `
    document.body.appendChild(scriptElement) // Append the script element to the body
  }

  const onClickNotify = () => {
    setOpenDont(true)
  }

  const onClickExplore = () => {
    const gameSection = document.getElementById("games")
    if (gameSection) {
      gameSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  const openProductPageModal = () => {
    setProductPageModal({ ...productPageModal, isOpen: true })
  }

  const CommonCardComponent = ({
    gameId,
    image,
    title,
    description1,
    description2,
    isComingSoon,
    isLabel,
    isClick,
  }) => {
    const game = { gameId, image, title }
    const gamesData = getGamesData?.find((item) => item?.gameId === gameId)

    return (
      <div class="phone:w-full md:w-1/2 lg:w-1/3 sm:w-1/2 mb-20 flex flex-col phone:px-1 sm:px-2 md:px-5 lg:px-4 xl:px-10 px-10 items-center justify-between">
        <div class="rounded-lg overflow-hidden relative">
          {!!isLabel && (
            <div className="absolute bottom-[10px] bg-[#FAA51A] border-[1px] border-black w-[200px] h-[41px] rounded-l-lg right-0 flex flex-row items-center justify-center">
              <p className="text-[20px] text-[#161616] font-semibold font-family-Sans">
                ₹ 999{" "}
                <sup className="text-[10px] text-[#161616] font-semibold font-family-Sans">
                  *all inclusive
                </sup>
              </p>

              {/* <p className="text-[20px] text-[#161616] font-semibold font-family-Sans ml-[5px]">
                399
              </p> */}
            </div>
          )}
          <img
            alt="content"
            className="object-cover hover:cursor-pointer"
            src={image}
            onClick={isClick ? handleClick : null}
          />
        </div>
        <h2
          class="text-[32px] font-bold text-[#161616] mt-6"
          onClick={isClick ? handleClick : null}
        >
          {title}
        </h2>
        <p
          className="mediumStrategy text-[16px] text-[#6E6C6A] mb-3"
          onClick={isClick ? handleClick : null}
        >
          {description1}
        </p>
        <p
          class="md:w-[84%] text-[16px] w-full text-[#161616] mediumStrategy"
          onClick={isClick ? handleClick : null}
        >
          {description2}
        </p>

        {!!isComingSoon ? (
          <>
            <ButtonComponents onClick={onClickNotify} className={"my-3"}>
              <p>NOTIFY ME</p>
              <div className="mx-[10px]">
                {!!isAlreadyOpenNotify ? (
                  <RiNotification2Fill
                    size={isMobile ? 16 : 18}
                    color={"black"}
                  />
                ) : (
                  <img src={notify_bell} className="w-[24px] h-[24px]" />
                )}
              </div>
            </ButtonComponents>
            <ButtonComponents
              style={{
                color: "#808080",
                borderColor: "#808080",
              }}
            >
              COMING SOON...
            </ButtonComponents>
          </>
        ) : (
          <>
            <div className="mainButtonView my-4">
              <div className="sm:w-[100%] w-[100%]">
                <ButtonComponents
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/playlist?list=PLUyT1cAF4rCW86ketHaIHIQavrhdCWs8Z"
                    )
                  }}
                >
                  <p className="mx-1">HOW TO PLAY</p>
                  <div>
                    <FaPlay />
                  </div>
                </ButtonComponents>
              </div>

              {/* <div className="sm:w-[34%] w-[42%]">
                <ButtonComponents onClick={() => handleAddToCart(game)}>
                  {!!gamesData ? (
                    <IoCheckmark size={18} color="black" />
                  ) : (
                    <GoPlus size={18} color="black" />
                  )}
                  <p>CART</p>
                  <div>
                    <img src={cart} className="w-auto h-[20px]" />
                  </div>
                </ButtonComponents>
              </div> */}
            </div>
            {/* <div id="product-component-1724437031525"></div> */}
            <ButtonComponents className={"bg-[#FAA51A]"} onClick={handleClick}>
              {/* PREORDER */}
              PROCEED TO BUY
            </ButtonComponents>
          </>
        )}
      </div>
    )
  }

  const CommonImageComponent = ({ gameId, image, title }) => {
    const game = { gameId, image, title }
    return (
      <div className="relative">
        {/* <img onClick={() => openProductPageModal()} src={image} className="homeBannerImageStyle" /> */}
        <img
          onClick={() => handleClick()}
          src={image}
          className="homeBannerImageStyle"
        />

        <div
          onClick={() => handleBannerStyle(game)}
          className={`absolute xl:bottom-[22px] xl:left-[167px] lg:left-[140px] md:bottom-[10px] md:left-[110px] sm:bottom-[5px] sm:left-[80px] phone:bottom-[5px] phone:left-[40px] flex fontStyle cursor-pointer`}
        ></div>
      </div>
    )
  }

  const CommonTestimonial = ({ name, subName, city, description }) => {
    return (
      <div className="text-black fontStyle">
        <div className="flex flex-row my-2">
          <p className="font-semibold">{name}</p>
          &nbsp;
          <p>{subName} </p>
          &nbsp;
          <p>{city}</p>
        </div>

        <p className="text-[15px]">{description}</p>
      </div>
    )
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 7000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }

  const CustomDots = ({ onClick, active }) => {
    return (
      <div className="py-1 lg:py-5 md:py-3 sm:py-2 px-2 flex items-center">
        <li
          className={
            active
              ? "bg-white cursor-pointer sm:w-[16px] sm:h-[16px] w-[12px] h-[12px] rounded-full"
              : "bg-[#EFEAE5] cursor-pointer sm:w-[10px] sm:h-[10px] w-[6px] h-[6px] rounded-full"
          }
          onClick={() => onClick()}
        ></li>
      </div>
    )
  }

  const OurMissionInfo = [
    {
      img: Our_Vision,
      title: "Our Vision",
      description:
        "We dream of a world where dusty old attics are replaced with game nights filled with Opod Play adventures. Imagine families strategizing like seasoned warriors, friends bonding over epic bluffs, and laughter echoing through homes across the globe. That's the OPOD PLAY utopia we're striving for!",
    },
    {
      img: Our_Mission,
      title: "Our Mission",
      description:
        "To prove that board games can be educational but fun,  more strategic than a game of kabaddi, and more thrilling than a rickshaw ride through rush hour traffic (Okay... maybe, not that last one).",
    },
    {
      img: The_Future,
      title: "The Future",
      description:
        "We're committed to making our games accessible to players worldwide, showcasing the beauty and depth of Indian storytelling through interactive entertainment. We're more than just a game company; we're a community of curious players.",
    },
  ]

  const CommonCardContainer = ({ img, name }) => {
    return (
      <div>
        <img src={img} className="w-auto h-[180px] md:h-[245px] mx-auto" />
        <p className="text-center font-bold text-white pt-10  text-[26px] md:text-[32px] leading-[30px] md:leading-[32px]">
          {name}
        </p>
      </div>
    )
  }

  const CommonInformationCardContainer = ({ items, index }) => {
    return (
      <div
        href="#"
        class={`sm:mb-[20px] mb-[50px] flex flex-col items-center md:flex-row`}
      >
        <div
          className={`w-[77%] flex justify-center mb-[24px] md:mb-[0px] md:w-[615px] mr-[25px] ${
            index % 2 === 0 ? null : "md:hidden md:mr-[0px]"
          }`}
        >
          <img
            src={items?.img}
            className="w-[250px] lg:w-[250px] object-contain md:w-[350px] sm:w-[250px] h-[180px] lg:h-[241px] md:h-[180px] sm:h-[250px] phone:h-[250px]"
          />
        </div>
        <div class="flex flex-col justify-between leading-normal items-center md:items-start">
          <div className="mb-[12px]">
            <h5 class="text-[26px] lg:text-[32px] md:text-[30px] sm:text-[28px] leading-[30px] md:leading-[32px] text-[#fff] font-bold">
              {items?.title}
            </h5>
          </div>
          <p class="sm:text-start text-center text-[14px] lg:text-[20px] sm:text-[18px] text-[#EFEAE5] leading-[21px]	md:leading-[36px] font-family-Sans">
            {items?.description}
          </p>
        </div>
        <div
          className={`hidden w-[77%] justify-center md:w-[615px] ml-[0px] ${
            index % 2 === 0
              ? null
              : "flex justify-center items-center md:flex md:ml-[25px] "
          }`}
        >
          <img
            src={items?.img}
            className="w-auto self-center h-[180px] lg:h-[241px] md:h-[180px] sm:h-[200px] phone:h-[100px]"
          />
        </div>
      </div>
    )
  }

  const CustomLeftArrow = ({ onClick }) => (
    <div
      onClick={() => onClick()}
      className="w-7 h-7 bg-[#00000070] absolute md:w-10 md:h-10 md:left-14 left-5 rounded-md flex justify-center items-center cursor-pointer"
    >
      <FaAngleLeft className="text-[16px] md:text-[22px]" color="#FFF" />
    </div>
  )

  const CustomRightArrow = ({ onClick }) => (
    <div
      onClick={() => onClick()}
      className="w-7 h-7 bg-[#00000070] absolute md:w-10 md:h-10 md:right-14 right-5 rounded-md flex justify-center items-center cursor-pointer"
    >
      <FaAngleRight className="text-[16px] md:text-[22px]" color="#FFF" />
    </div>
  )

  return (
    <>
      <div className="max-w-fit overscroll-none">
        <div id="home" ref={homeRef}>
          <Carousel
            responsive={responsive}
            infinite={true}
            showDots={true}
            dotListClass="custom-dot-list-style"
            autoPlay={true}
            autoPlaySpeed={7000}
            itemClass="image-item"
            customDot={<CustomDots />}
            containerClass="carousel-container"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {bannerData.map((item) => (
              <CommonImageComponent
                gameId={item.id}
                image={item.image}
                title={item?.title}
              />
            ))}
          </Carousel>

          <div className="homeBackgroundStyle p-[2rem] xl:p-[3.5rem] lg:p-[2rem]">
            {isMobile ? (
              <img src={opod_play_mobile} className="my-[30px]" />
            ) : (
              <img src={opod_play} className="opodPlayLogoStyle" />
            )}

            <p className="text-xl font-semibold text-center my-5 text-white sm:text-2xl lg:text-4xl">
              Get set to dominate the table
            </p>

            <button
              onClick={onClickExplore}
              className="bg-[#000] text-white font-semibold border-2 border-zinc-50  phone:text-[14px] md:text-[20px] px-6 py-3 rounded-full"
            >
              EXPLORE GAMES
            </button>

            <img
              src={side_img}
              className="absolute right-0 min-[320px]:top-[200px] max-[600px]:top-[20px] sm:top-[20px] w-auto h-[70px] phone:h-[80px] md:h-[200px] sm:h-[150px] lg:h-[300px] tabPhone:h-[150px]"
            />
            <img
              src={side_img2}
              className="absolute left-0 bottom-0 w-auto phone:bottom-[40px] sm:bottom-0 h-[40px] sm:h-[100px] phone:h-[60px] md:h-[150px] lg:h-[200px] "
            />
          </div>
        </div>
        <section class="gameBackgroundImage text-gray-600">
          <div id="games" ref={gameSectionRef} class="py-20 px-10">
            <div class="flex flex-wrap relative text-center">
              <CommonCardComponent
                gameId={1}
                image={card_banner}
                isLabel={true}
                title={"S for Startup "}
                description1={"Card Game | Medium Strategy | 2 - 4 Players"}
                description2={
                  "Ready to be your own boss? Challenge your friends and launch your own startup empire!"
                }
                isClick={true}
              />
              <CommonCardComponent
                gameId={2}
                image={ComingSoon}
                title={"Coming soon!"}
                description1={"Light Strategy | 1 - 5 Players"}
                description2={
                  "We're shuffling the deck for something epic. Stay tuned!"
                }
                isComingSoon={true}
                isClick={false}
              />
              <CommonCardComponent
                gameId={3}
                image={ComingSoon}
                title={"Coming soon!"}
                description1={"Light Strategy | 1 - 5 Players"}
                description2={
                  "We're shuffling the deck for something epic. Stay tuned!"
                }
                isComingSoon={true}
                isClick={false}
              />
            </div>

            <div className="mainContainerView">
              <div className="flex flex-row gap-2 mx-[40px] my-[10px] self-start">
                <img src={down_green} className="w-[16px] h-[16px]" />
                <img src={down_red} className="w-[16px] h-[16px]" />
                <img src={down_yellow} className="w-[16px] h-[16px]" />
              </div>

              <div className="flex relative justify-center self-center">
                <FaQuoteLeft
                  color="#000"
                  className={
                    "absolute w-[72px] h-[72px] p-[10px] bottom-[-35px] bg-[#4BC2AE] rounded-full"
                  }
                />
              </div>

              <div className="border-2 border-black rounded-[50px] p-6 gap-10">
                <Carousel
                  responsive={responsive}
                  dotListClass="custom-dot-list-style"
                  autoPlay={true}
                  autoPlaySpeed={5000}
                  infinite={true}
                  itemClass="image-item"
                  containerClass="carousel-container"
                  arrows={false}
                  ref={swipeRef}
                >
                  <CommonTestimonial
                    name={"Aditi Singh,"}
                    city={"Mumbai"}
                    subName={"Entrepreneur,"}
                    description={
                      "S for Startup card game is the perfect icebreaker at our co-working meetups! It gets everyone talking about their own business ideas."
                    }
                  />

                  <CommonTestimonial
                    name={"Professor Gaurav,"}
                    city={"IIT Bombay"}
                    subName={"SJSOM,"}
                    description={
                      "S for Startup card game is a fun and engaging way to introduce core business concepts."
                    }
                  />

                  <CommonTestimonial
                    name={"Rahul Kapoor,"}
                    city={"Bangalore"}
                    subName={"Software developer,"}
                    description={
                      "S for Startup card game is the new game night rage at our flat! It's fast-paced, strategic, and keeps everyone on their toes."
                    }
                  />

                  <CommonTestimonial
                    name={"Shreyas Patel,"}
                    city={"Pune"}
                    subName={"Entrepreneur,"}
                    description={
                      "Even my kids are hooked on S for Startup card game! It's a fantastic introduction to the world of startups, presented in a way that's both fun and educational."
                    }
                  />
                </Carousel>
              </div>
              <div className="flex flex-row gap-2 mx-[40px] my-[10px] self-end">
                <img src={down_green} className="w-[16px] h-[16px]" />
                <img src={down_yellow} className="w-[16px] h-[16px]" />
                <img src={down_red} className="w-[16px] h-[16px]" />
              </div>
            </div>
          </div>
        </section>

        <div
          id="about"
          ref={aboutRef}
          className="mainContainerStyle px-[1.5rem]"
        >
          <div className="py-[80px]">
            <img
              src={logoImage}
              className="p-3 w-auto h-[55px] md:h-[75px] sm:h-[65px] phone:p-0 mx-auto"
            />
            <p className="text-center mt-[40px] -mb-[30px] text-[16px] lg:text-[32px] md:text-[28px] phone:text-[24px] text-white font-medium">
              Get Set To Experience Gamified Storytelling
            </p>
          </div>
          <p className="text-white text-[16px] lg:text-[24px] md:text-[20px] leading-[21px] sm:leading-[36px] lg:px-[5.25rem] md:px-[3rem] px-0 fontStyle">
            We're OPOD PLAY, a passionate bunch of board game enthusiasts
            scattered across the vibrant chaos of Amchi Mumbai and the
            filter-coffee-fueled energy of Namma Bengaluru. Founded in 2024,
            we've been united by the love for strategic play, a healthy dose of
            chai, and a burning desire to unleash the epic tales of India onto
            the world... one board game at a time!
          </p>

          <div className="md:grid-cols-2 lg:grid-cols-3 lg:gap-0 gap-[100px] grid grid-cols-1 py-[70px]">
            <CommonCardContainer img={about_1} name={"Design"} />
            <CommonCardContainer img={about_2} name={"Build"} />
            <CommonCardContainer img={about_3} name={"Distribute"} />
          </div>

          {/* Design 1px graoh line */}
          <div className="md:border-t-0 border-t-2 border-[#EFEAE5] w-[90%] mx-auto"></div>

          <div className="gap-4 px-0 md:px-20 py-7">
            {OurMissionInfo.map((items, index) => {
              return (
                <CommonInformationCardContainer items={items} index={index} />
              )
            })}
          </div>
        </div>
        <DontMissOut
          open={openDont}
          setOpen={setOpenDont}
          setIsAlreadyOpenNotify={setIsAlreadyOpenNotify}
        />
        {/* <Cookies open={openCookies} setOpen={setOpenCookies} /> */}
      </div>
      <Footer id={"contact"} ref={contactSectionRef} />

      {/* <PrductPageModal
        productPageModal={productPageModal}
        setProductPageModal={setProductPageModal}
      /> */}
      <ProductCheckoutModal
        productPageModal={productPageModal}
        setProductPageModal={setProductPageModal}
      />
    </>
  )
}
