export const NavItems = [
  {
    id: 1,
    path: "/#home",
    text: "Home",
  },
  {
    id: 2,
    path: "/#games",
    text: "Games",
  },
  {
    id: 3,
    path: "/#about",
    text: "About",
  },
  {
    id: 4,
    path: "/#contact",
    text: "Contact",
  },
];
