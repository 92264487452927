// Library imports
import React from "react";
import {
  LastPrivacy,
  PrivacyData,
  PrivacySharedData,
  QuestionsData,
  WhoInformationShared,
} from "../../apis/constants";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy({
  setIsHeaderShow,
  setIsPreOrderHeader,
}) {
  setIsHeaderShow(false);
  setIsPreOrderHeader(false);
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate("/");
  };

  const CommonSummary = ({ title, description }) => {
    return (
      <div>
        <p className="font-bold text-[14px]">{title}</p>
        <p className="my-1">{description}</p>
      </div>
    );
  };

  const CommonLiTagComponent = ({ title, description }) => {
    return (
      <div className="mx-4">
        <li className="font-semibold">{title}</li>
        <p>{description}</p>
      </div>
    );
  };

  const CommonSpanSummary = ({ title, description }) => {
    return (
      <li className="font-semibold mx-4">
        {title} <span className="font-normal">{description}</span>
      </li>
    );
  };

  const CommonQuestions = ({ title, par1, par2 }) => {
    return (
      <>
        <p className="font-bold text-[14px]">{title}</p>
        <p>{par1}</p>
        {par2 && <p>{par2}</p>}
      </>
    );
  };

  return (
    <>
      <div className="bg-[#EFEAE4] fontStyle flex flex-row p-5 justify-between items-center sticky top-0">
        <div className="flex flex-row items-center gap-8">
          <IoMdArrowBack size={25} cursor={"pointer"} onClick={onClickBack} />

          <div>
            <p className="text-[24px]">Privacy policy</p>
            <p>Last updated June 01, 2024</p>
          </div>
        </div>
      </div>

      <div className="fontStyle px-[20px]">
        <div className="text-[16px] p-[20px] lg:p-[20px] md:p-[18px] sm:p-[16px] phone:p-[14px] flex flex-col gap-5">
          <p>
            Thank you for choosing to be part of our community at Opod Play (“
            Company “, “we“, “us“, “our“). We are committed to protecting your
            personal information and your right to privacy. If you have any
            questions or concerns about this privacy notice, or our practices
            with regards to your personal information, please contact us at
            hello@opod.in.
          </p>
          <p>
            When you visit our website, http://play.opod.in (the “Website“), and
            more generally, use any of our services (the “Services“, which
            include the Website), we appreciate that you are trusting us with
            your personal information. We take your privacy very seriously. In
            this privacy notice, we seek to explain to you in the clearest way
            possible what information we collect, how we use it and what rights
            you have in relation to it. We hope you take some time to read
            through it carefully, as it is important. If there are any terms in
            this privacy notice that you do not agree with, please discontinue
            use of our Services immediately.
          </p>
          <p>
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events.
          </p>
          <p>
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </p>
          <CommonSummary
            title={"WHAT INFORMATION DO WE COLLECT?"}
            description={
              "We collect personal information that you voluntarily provide to us when you register on the  Website, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Website or otherwise when you contact us."
            }
          />
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Website , the choices you make and
            the products and features you use. The personal information we
            collect may include the following:
          </p>
          <CommonLiTagComponent
            title={"Personal Information Provided by You"}
            description={
              "We collect names; email addresses; phone numbers; mailing addresses; and other similar information."
            }
          />
          <CommonLiTagComponent
            title={"Payment Data"}
            description={
              "We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Respective Payment gateway Provider. You may find their privacy notice link(s) on their respective websites."
            }
          />
          <CommonLiTagComponent
            title={"Social Media Login Data"}
            description={
              "We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account."
            }
          />
          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>
          <p className="font-bold text-[16px]">
            Information automatically collected
          </p>
          <p>
            In Short: Some information — such as your Internet Protocol (IP)
            address and/or browser and device characteristics — is collected
            automatically when you visit our Website.
          </p>
          <p>
            We automatically collect certain information when you visit, use or
            navigate the Website. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our Website and other technical information.
            This information is primarily needed to maintain the security and
            operation of our Website, and for our internal analytics and
            reporting purposes. Like many businesses, we also collect
            information through cookies and similar technologies.
          </p>
          <p className="font-bold text-[16px]">
            The information we collect includes:
          </p>
          <CommonLiTagComponent
            title={"Log and Usage Data"}
            description={
              "Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Website (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called ‘crash dumps’) and hardware settings)."
            }
          />
          <CommonLiTagComponent
            title={"Device Data"}
            description={
              "We collect device data such as information about your computer, phone, tablet or other device you use to access the Website. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information."
            }
          />
          <CommonLiTagComponent
            title={"Location Data"}
            description={
              "We collect location data such as information about your device’s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use."
            }
          />
          <p>
            to access the Website. For example, we may use GPS and other
            technologies to collect geolocation data that tells us your current
            location (based on your IP address). You can opt out of allowing us
            to collect this information either by refusing access to the
            information or by disabling your Location setting on your device.
            Note however, if you choose to opt out, you may not be able to use
            certain aspects of the Services.
          </p>
          <CommonSummary
            title={"HOW DO WE USE YOUR INFORMATION?"}
            description={
              "We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below."
            }
          />
          <p className="font-bold text-[16px]">
            We use the information we collect or receive:
          </p>

          {PrivacyData.map((data) => (
            <CommonLiTagComponent
              key={data.id}
              title={data.title}
              description={data.description}
            />
          ))}

          <p className="font-semibold text-[14px]">
            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </p>

          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>

          {PrivacySharedData.map((data) => (
            <CommonSpanSummary
              key={data.id}
              title={data.title}
              description={data.description}
            />
          ))}

          <p className="font-bold text-[14px]">
            WHO WILL YOUR INFORMATION BE SHARED WITH?
          </p>

          <p>
            We only share and disclose your information with the following
            categories of third parties.
          </p>

          {WhoInformationShared.map((data) => (
            <CommonLiTagComponent
              key={data.id}
              title={data.title}
              description={data.description}
            />
          ))}

          {QuestionsData.map((data) => (
            <CommonQuestions
              key={data.id}
              title={data.title}
              par1={data.par1}
              par2={data.par2}
            />
          ))}

          <p className="font-bold text-[14px]">Account Information</p>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <p>
            Log in to your account settings and update your user account. Upon
            your request to terminate your account, we will deactivate or delete
            your account and information from our active databases. However, we
            may retain some information in our files to prevent fraud,
            troubleshoot problems, assist with any investigations, enforce our
            Terms of Use and/or comply with applicable legal requirements.
          </p>
          <CommonSpanSummary
            title={"Cookies and similar technologies:"}
            description={
              "Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Website. "
            }
          />

          <CommonSpanSummary
            title={"Opting out of email marketing: "}
            description={
              "You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may: "
            }
          />

          {LastPrivacy.map((data) => (
            <CommonSummary
              key={data.id}
              title={data.title}
              description={data.description}
            />
          ))}
        </div>
      </div>
    </>
  );
}
