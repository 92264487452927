// Library imports
import { Box, Modal } from "@mui/material";
import React from "react";
import { IoMdClose } from "react-icons/io";

export default function YouAreIn({ openConfirm, setOpenConfirm }) {
  const handleClose = () => setOpenConfirm(false);

  const onClickClose = () => {
    setOpenConfirm(false);
  };

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "70%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    p: 5,
    overflow: "scroll",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <div>
      <Modal
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={costumeStyle}>
          <div className=" h-full flex flex-col justify-between">
            <div>
              <div className="flex flex-row justify-between items-center">
                <p className="font-bold text-[20px] xl:text-[32px] lg:text-[30px] md:text-[20px] phone:text-[18px]">
                  You’re in!
                </p>
                <IoMdClose
                  cursor={"pointer"}
                  className="text-[18px] xl:text-[32px] lg:text-[28px] md:text-[24px] phone:text-[20px] text-center "
                  color="#6D6B69"
                  onClick={onClickClose}
                />
              </div>
              <p className="lg:text-[20px] md:text-[14px] sm:text-[14px] phone:text-[12px] text-[14px] my-5 fontStyle">
                You're now on the list to be notified when [product name]
                becomes available for pre-order. We'll send you an email to
                [email address] as soon as pre-orders open, and you can secure
                your copy before they're gone.
              </p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
