// Library imports
import { Box, Modal } from "@mui/material"
import React from "react"
import { IoMdClose } from "react-icons/io"
import { useMediaQuery } from "react-responsive"

export default function ProductCheckoutModal({
  productPageModal,
  setProductPageModal,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 })
  const handleClose = () =>
    setProductPageModal({ ...productPageModal, isOpen: false })

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    ...(isTablet && { height: "90%" }),
    maxHeight: "90%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    overflow: "scroll",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  }

  return (
    <Modal
      open={productPageModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={costumeStyle}>
        <div className="bg-[#EFEAE4] fontStyle flex p-5 justify-end items-center sticky top-0">
          <IoMdClose
            cursor={"pointer"}
            size={24}
            color="#6D6B69"
            onClick={handleClose}
          />
        </div>
        <div className="   md:h-[100%] px-7 pb-7 ">
          <div id="product-component-1730223864376"></div>
        </div>
        {/* <div id="product-component"></div> */}
      </Box>
      {/* <div id="product-component"></div> */}
    </Modal>
  )
}
