export const PrivacyData = [
  {
    id: 1,
    title: "To facilitate account creation and logon process",
    description:
      "If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.",
  },
  {
    id: 2,
    title: "To post testimonials",
    description:
      "We post testimonials on our Website that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at hello@opod.in  and be sure to include your name, testimonial location, and contact information.",
  },
  {
    id: 3,
    title: "Request feedback",
    description:
      "We may use your information to request feedback and to contact you about your use of our Website.",
  },
  {
    id: 4,
    title: "To enable user-to-user communications",
    description:
      "We may use your information in order to enable user-to-user communications with each user’s consent.",
  },
  {
    id: 5,
    title: "To manage user accounts",
    description:
      "We may use your information for the purposes of managing our account and keeping it in working order.",
  },
  {
    id: 6,
    title: "Fulfill and manage your orders",
    description:
      "We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Website.",
  },
  {
    id: 7,
    title: "Administer prize draws and competitions",
    description:
      "We may use your information to administer prize draws and competitions when you elect to participate in our competitions.",
  },
  {
    id: 8,
    title: "To deliver and facilitate delivery of services to the user",
    description:
      "We may use your information to provide you with the requested service.",
  },
  {
    id: 9,
    title: "To respond to user inquiries/offer support to users",
    description:
      "We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.",
  },
  {
    id: 10,
    title: "To send you marketing and promotional communications",
    description:
      "We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Website , subscribing to marketing or otherwise contacting us, we will collect personal information from you.",
  },
];

export const PrivacySharedData = [
  {
    id: 1,
    title: "Consent:",
    description:
      "We may process your data if you have given us specific consent to use your personal information for a specific purpose.",
  },
  {
    id: 2,
    title: "Legitimate Interests:",
    description:
      "We may process your data when it is reasonably necessary to achieve our legitimate business interests.",
  },
  {
    id: 3,
    title: "Performance of a Contract:",
    description:
      "Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.",
  },
  {
    id: 4,
    title: "Legal Obligations:",
    description:
      "We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).",
  },
  {
    id: 5,
    title: "Vital Interests:",
    description:
      "We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved. More specifically, we may need to process your data or share your personal information in the following situations:",
  },
  {
    id: 6,
    title: "Business Transfers:",
    description:
      "We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.",
  },
  {
    id: 7,
    title: "Vendors, Consultants and Other Third-Party Service Providers:",
    description:
      "We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Website , which will enable them to collect data on our behalf about how you interact with our Website over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.",
  },
];

export const WhoInformationShared = [
  {
    id: 1,
    title: "Data Analytics Services",
  },
  {
    id: 2,
    title: "Order Fulfilment Service Providers",
  },
  {
    id: 3,
    title: "Payment Processors",
  },
  {
    id: 4,
    title: "Sales & Marketing Tools",
  },
  {
    id: 5,
    title: "User Account Registration & Authentication Services",
  },
];

export const QuestionsData = [
  {
    id: 1,
    title: "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
    par1: "We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice .",
  },
  {
    id: 2,
    title: " HOW DO WE HANDLE YOUR SOCIAL LOGINS?",
    par1: "Our Website offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice .",
    par2: "We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Website. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.",
  },
  {
    id: 3,
    title: "HOW LONG DO WE KEEP YOUR INFORMATION?",
    par1: "We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.",
    par2: "When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.",
  },
  {
    id: 4,
    title: "HOW DO WE KEEP YOUR INFORMATION SAFE?",
    par1: "We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.",
  },
  {
    id: 5,
    title: "WHAT ARE YOUR PRIVACY RIGHTS?",
    par1: "If you have questions or comments about your privacy rights, you may email us at hello@opod.in",
  },
];

export const LastPrivacy = [
  {
    id: 1,
    title: "CONTROLS FOR DO-NOT-TRACK FEATURES",
    description:
      "Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.",
  },
  {
    id: 1,
    title: "DO WE MAKE UPDATES TO THIS NOTICE?",
    description:
      "We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.",
  },
  {
    id: 1,
    title: "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
    description:
      "If you have questions or comments about this notice, you may email us at hellp@opod.in",
  },
  {
    id: 1,
    title:
      "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
    description:
      "Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please drop an email to hello@opod.in.",
  },
];

export const TermsAndConditionsData = [
  {
    id: 1,
    title: "Account Security:",
    description:
      "You are solely responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately if you become aware of any unauthorized use of your account or password. We will not be liable for any losses, damages, or claims arising from your failure to keep your account information secure.",
  },
  {
    id: 2,
    title: "Products and Services:",
    description:
      " We offer a variety of products and services on our e-commerce website, including but not limited to games and accessories. We make every effort to ensure that the products and services we offer are of high quality and accurate. However, we cannot guarantee that the descriptions, images, and other content on our website are error-free or complete.",
  },
  {
    id: 3,
    title: "Ordering and Payment:",
    description:
      "Once you have selected the products and services you wish to purchase, you will be required to provide us with your payment information. We accept a variety of payment methods, including credit cards, debit cards, and online payment systems. All payments made on our website are processed securely and encrypted to protect your personal and financial information.",
  },
  {
    id: 4,
    title: "Shipping and Delivery: ",
    description:
      "We make every effort to ensure that your products are shipped and delivered to you in a timely and efficient manner. The estimated delivery times and shipping fees for each option will be displayed during the checkout process.",
  },
  {
    id: 5,
    title: "Returns and Refunds:",
    description:
      "We understand that sometimes a product may not meet your expectations or may arrive damaged. We offer a hassle-free return and refund policy for eligible products. Please refer to our website for our return policy.",
  },
  {
    id: 6,
    title: "User Conduct:",
    description:
      "You agree to use our website only for lawful purposes and in a manner consistent with these terms and conditions. You are prohibited from using our website to engage in any illegal, fraudulent, or harmful activities. You are also prohibited from posting any content that is offensive, threatening, or defamatory.",
  },
  {
    id: 7,
    title: "Intellectual Property:",
    description:
      "All content on our e-commerce website, including but not limited to text, images, videos, and graphics, is the property of our company and is protected by copyright laws. You are prohibited from using any content on our website without our express written permission.",
  },
  {
    id: 8,
    title: "Limitation of Liability:",
    description:
      "We will not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our e-commerce website or any products or services purchased through our website.",
  },
  {
    id: 9,
    title: "Governing Law:",
    description:
      "These terms and conditions will be governed by and construed in accordance with the laws of India. Any disputes arising from these terms and conditions will be resolved by the courts of India.",
  },
  {
    id: 10,
    title: "Modification:",
    description:
      "We reserve the right to modify these terms and conditions at any time. Any changes will be posted on our website and will be effective immediately. Your continued use of our website after any changes to these terms and conditions constitutes your acceptance of the updated terms.",
  },
];

export const RefundsReturn = [
  {
    id: 1,
    title: "Credit/Debit Card:",
    description:
      " If you made your payment using a credit or debit card, the refunds will show back up in your bank account within five to seven business days.",
  },
  {
    id: 2,
    title: "UPI:",
    description:
      "If you made your payment using any UPI application, the refund will show up in your bank account within one to two business days.",
  },
  {
    id: 3,
    title: "Digital Wallets:",
    description:
      "If you made your payment using any digital wallet like Paytm, the refund will show up in your bank account within one to two business days.",
  },
  {
    id: 4,
    title: "Net Banking:",
    description:
      "If you made your payment using a credit or debit card, the refunds will show back up in your bank account within five to seven business days.",
    extraTitle: "Missing Information",
  },
  {
    id: 5,
    title: "Wrong Address: ",
    description:
      "If theconsumer provides an address that is considered insufficient by the courier or is a wrong address, the product will be returned to our facility. The consumer will need to pay for the re-shipment cost once we have confirmed an updated address with the Buyer (if and as applicable).",
  },
  {
    id: 6,
    title: "Unclaimed / Order not received: ",
    description:
      "Shipments that go unclaimed (the buyer isn’t able to receive it at the time of delivery) are returned to our facility. Opod Play cannot refund products that are produced and shipped. You can opt for re-shipment by writing to us at hello@opod.in and we’ll charge you the applicable delivery charges.",
  },
  {
    id: 7,
    title: "Note: ",
    description:
      "This policy is subject to change without any prior notice. And the latest version of the policy will always be applicable to the previous support tickets. This is to ensure a consistent and rewarding experience for Opod Play customers.",
  },
];
