import { ADD_TO_CART, REMOVE_FROM_CART } from "../action/gamesCart";
import { INCREMENT, DECREMENT } from "../action/gameCountAction";

const initialState = {
  items: [],
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const existingGameIndex = state.items.findIndex((item) => item.gameId === action.payload.gameId);
      if (existingGameIndex >= 0) {
        const updatedItems = state.items.map((item, index) =>
          index === existingGameIndex ? { ...item, count: item.count + 1 } : item
        );
        return {
          ...state,
          items: updatedItems,
        };
      } else {
        return {
          ...state,
          items: [...state.items, { ...action.payload, count: 1 }],
        };
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter((item) => item.gameId !== action.payload),
      };

    case INCREMENT:
      return {
        ...state,
        items: state.items.map((item) => (item.gameId === action.payload ? { ...item, count: item.count + 1 } : item)),
      };

    case DECREMENT:
      return {
        ...state,
        // items: state.items.map((item) =>
        //   item.gameId === action.payload ? { ...item, count: Math.max(item.count - 1, 1) } : item
        // ),
        items: state.items
          .map((item) => (item.gameId === action.payload ? { ...item, count: item.count - 1 } : item))
          .filter((item) => item.count >= 1),
      };

    default:
      return state;
  }
};
