import { useState } from "react";
import { Route, Routes, BrowserRouter as Router, HashRouter } from "react-router-dom";
import "./App.css";

import { Header } from "./components/header";
import Home from "./pages/Home/Home";
import Preorder from "./pages/Preorder/Preorder";
import Cart from "./pages/Cart/Cart";
import CheckOut from "./pages/CheckOut/CheckOut";
import { DropdownContext } from "./context/dropdown";
import ProrderHeader from "./components/header/prorderHeader";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import ShippingPolicy from "./pages/ShippingPolicy/ShippingPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy/ReturnPolicy";

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHeaderShow, setIsHeaderShow] = useState(true);
  const [isPreOrderHeader, setIsPreOrderHeader] = useState(false);

  return (
    <DropdownContext.Provider value={{ isDropdownOpen, setIsDropdownOpen }}>
      <Router>
        {!!isHeaderShow && <Header />}
        {!!isPreOrderHeader && <ProrderHeader />}
        <Routes>
          <Route
            path="/"
            element={
              <Home
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/preorder"
            element={
              <Preorder
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/cart"
            element={
              <Cart
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/checkout"
            element={
              <CheckOut
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <TermsAndConditions
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/shipping-policy"
            element={
              <ShippingPolicy
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
          <Route
            path="/return-policy"
            element={
              <ReturnPolicy
                setIsHeaderShow={setIsHeaderShow}
                setIsPreOrderHeader={setIsPreOrderHeader}
              />
            }
          />
        </Routes>
      </Router>
    </DropdownContext.Provider>
  );
}

export default App;
