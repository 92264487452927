// Library imports
import { useContext } from "react"
import "./index.css"
import { useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"

// Local imports
import { NavBar } from "./NavBar"
import nav_icon from "../../assets/pngs/nav_icon.png"
import { NavButton } from "./NavButton"
import cart from "../../assets/pngs/cart_white.png"
import { DropdownContext } from "../../context/dropdown"
import { useNavigate } from "react-router-dom"
import CommonCartComponent from "../common/CommonCartComponent"

export const Header = () => {
  const { setIsDropdownOpen } = useContext(DropdownContext)
  const data = useSelector((state) => state.cart.items)
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const navigate = useNavigate()
  const totalCount = data.reduce((total, item) => total + item.count, 0)

  const toggleDrawer = (newOpen) => () => {
    if (isMobile) {
      navigate("/cart")
    } else {
      setIsDropdownOpen(newOpen)
    }
  }

  const onClickLogo = () => {
    navigate("/#home")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <header className="bg-[#E53B49] max-sm:px-5 sm:px-[5vw] lg:px-[80px] h-16 flex justify-between items-center sticky top-0 z-[1001] drop-shadow-md">
      <NavButton />

      <div onClick={onClickLogo} className="w-[53px] h-auto">
        <img
          src={nav_icon}
          className="w-[48px] h-[57px] max-sm:mx-auto cursor-pointer"
        />
      </div>
      <NavBar />

      {/* <div onClick={toggleDrawer(true)} className="relative cursor-pointer">
        <img src={cart} className="w-auto h-[35px]" />
        {totalCount > 0 && (
          <p className="bg-[#000] flex justify-center items-center pb-[2px] text-center text-[14px] rounded-full top-0 right-0 text-white w-[20px] h-[20px] absolute">
            {totalCount}
          </p>
        )}
      </div> */}

      <CommonCartComponent />
    </header>
  )
}
