// Library imports
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";

// Local imports
import { decrement, increment } from "../../redux/action/gameCountAction";
import FooterModal from "../../components/Modals/FooterModal";
import { useMediaQuery } from "react-responsive";
import { CheckOutModal } from "../../components/Modals/CheckOutModal";
import nav_icon from "../../assets/pngs/nav_icon.png";
import backButton from "../../assets/pngs/backButton.png";
import errorImage from "../../assets/pngs/error_round.png";
import successImage from "../../assets/pngs/success_round.png";

export default function CheckOut({ setIsHeaderShow, setIsPreOrderHeader }) {
  setIsHeaderShow(false);
  setIsPreOrderHeader(false);

  const dispatch = useDispatch();
  const gameData = useSelector((state) => state.cart.items);
  const isMobile = useMediaQuery({ minWidth: 576 });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [categoryFlag, setCategoryFlag] = useState(null);

  const AccSchema = Yup.object().shape({
    fullName: Yup.string().required("Please Enter Full Name"),
    phone: Yup.string().required("Please Enter Phone Number"),
    email: Yup.string().required("Please Enter Email"),
    address1: Yup.string().required("Please Enter Address"),
    address2: Yup.string().required("Please Enter Address"),
    city: Yup.string().required("Please Enter City"),
    state: Yup.string().required("Please Enter State"),
    country: Yup.string().required("Please Enter Country"),
    pinCode: Yup.string().required("Please Enter Pin Code"),
    couponCode: Yup.string("Please Enter Coupon Code"),
  });

  useEffect(() => {
    if (isMobile) {
      window.location.href = "#/preorder";
      setIsHeaderShow(true);
    }
  }, [isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (gameData.length === 0) {
      window.location.href = "/#games";
    }
  }, [gameData]);

  const onClickLogo = () => {
    navigate("/#home");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const LabelContainer = ({ id, title }) => {
    return (
      <div className="flex my-2">
        <div>
          <p className="text-[24px] border-[1px] border-[#000] w-[38px] h-[38px] flex items-center justify-center rounded-full text-[#000] font-semibold font-family-Sans">
            {id}
          </p>
        </div>

        <div className="text-[24px] ml-[20px] text-[#000] font-family-Sans">
          {title}
        </div>
      </div>
    );
  };

  const CommonInput = ({
    value,
    errors,
    touched,
    name,
    onChange,
    placeholder,
  }) => {
    return (
      <div className="w-full">
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          className="w-full h-[35px] border-[1px] border-[#000] rounded-lg px-3 bg-[#EFEAE4]"
        />
        {errors && touched && (
          <div className="text-[#E53B49] mt-2 font-[14px] self-start">
            {errors}
          </div>
        )}
      </div>
    );
  };

  const handleTerms = () => {
    setOpen(true);
    setCategoryFlag("TERMS_CONDITIONS");
  };

  const handleShipping = () => {
    setOpen(true);
    setCategoryFlag("SHIPPING_POLICY");
  };

  const handlePrivacy = () => {
    setOpen(true);
    setCategoryFlag("PRIVACY_POLICY");
  };

  const handleReturn = () => {
    setOpen(true);
    setCategoryFlag("RETURN_POLICY");
  };

  const ErrorComponent = () => {
    return (
      <div className="flex flex-row items-center justify-between mt-2">
        <div className="flex flex-row items-center gap-2 ">
          <img src={errorImage} className="w-[24px] h-[24px]" />
          <p className="text-[#E53B49] font-[14px] self-start">
            Invalid Coupon Code
          </p>
        </div>
        <p className="font-semibold text-[20px] flex absolute right-[20px]">
          INVALID
        </p>
      </div>
    );
  };

  return (
    <header className="bg-[#E53B49] h-[60px] sticky">
      <div className="flex flex-row items-center">
        <div
          className="flex flex-row items-center mx-5 cursor-pointer"
          onClick={onClickLogo}
        >
          <img src={backButton} className="w-auto h-[32px]" />
          <p className="font-semibold text-[16px] text-white">Cancel</p>
        </div>

        <div
          onClick={onClickLogo}
          className="w-auto flex flex-row items-center h-auto mx-auto"
        >
          <img
            src={nav_icon}
            className="w-[48px] h-[57px] max-sm:mx-auto cursor-pointer"
          />
        </div>

        <div className="w-[100px]" />
      </div>

      <div className="fontStyle bg-[#EFEAE4]">
        <div className="p-5">
          <div className="flex items-center flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              <p className="text-[24px] orderSummaryStyle">Order Summary</p>
            </div>
          </div>

          {gameData?.map((item, index) => (
            <>
              <div
                key={index}
                className="grid grid-flow-row-dense grid-cols-9 grid-rows-1 pb-[10px] my-5"
              >
                <div className="col-span-2 w-[100%]">
                  <img src={item?.image} alt={item?.title} />
                </div>
                <div className="pl-[20px] flex flex-col justify-between col-span-7">
                  <div className="flex items-center justify-between">
                    <div className="text-[24px] font-semibold font-family-Sans">
                      {item?.title}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex">
                      <p className="crossed-text text-[20px] text-[#6e6c6a] font-semibold font-family-Sans">
                        ₹699
                      </p>
                      <p className="text-[20px] text-[#161616] font-semibold font-family-Sans ml-[5px]">
                        Free
                      </p>
                    </div>
                    <div className="flex items-center gap-3">
                      <div
                        className="plusMinusStyle cursor-pointer"
                        onClick={() => dispatch(decrement(item.gameId))}
                      >
                        <FaMinus size={15} />
                      </div>
                      <div className="numberOfGamesStyle min-w-[30px] text-center text-[20px] border-x-[1px] border-[#7F7F7F]">
                        {item?.count}
                      </div>
                      <div
                        className="plusMinusStyle cursor-pointer"
                        onClick={() => dispatch(increment(item.gameId))}
                      >
                        <FaPlus size={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{ couponCode: "" }}
                validationSchema={AccSchema}
                onSubmit={({ resetForm }) => {
                  resetForm();
                }}
                validate={(values) => {
                  const errors = {};
                  if (!/^[A-Z0-9]+$/.test(values.couponCode)) {
                    errors.couponCode = <ErrorComponent />;
                  }
                  return errors;
                }}
              >
                {({ errors, touched, values, handleSubmit, handleChange }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-row justify-between my-5"
                  >
                    <div className="flex w-full flex-col">
                      <input
                        placeholder="Enter coupon code"
                        value={values.couponCode}
                        name="couponCode"
                        onChange={(e) => handleChange(e)}
                        className="w-[70%] h-[40px] border-[1px] border-[#000] rounded-lg px-3 bg-[#EFEAE4]"
                      />
                      {errors.couponCode &&
                      values.couponCode &&
                      touched.couponCode ? (
                        <div className="text-[#E53B49] mt-2 font-[14px]">
                          {errors.couponCode}
                        </div>
                      ) : (
                        values.couponCode &&
                        touched.couponCode && (
                          <div className="flex flex-row items-center justify-between mt-2">
                            <div className="flex flex-row items-center gap-2">
                              <img
                                src={successImage}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#13B57B] font-[14px] self-start">
                                60% off applied on you order
                              </p>
                            </div>
                            <p className="font-semibold text-[#13B57B] text-[20px] flex absolute right-[28px]">
                              - ₹ 798
                            </p>
                          </div>
                        )
                      )}
                    </div>

                    <button
                      type="submit"
                      className={`underline ${
                        touched.couponCode ? "text-black" : "text-[#808080]"
                      } underline-offset-2 text-[20px] font-semibold self-start pt-1`}
                    >
                      APPLY
                    </button>
                  </form>
                )}
              </Formik>
            </>
          ))}

          <div className="border-[1px] border-[#000]"></div>

          <Formik
            initialValues={{
              fullName: "",
              email: "",
              phone: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              country: "",
              pinCode: "",
            }}
            validationSchema={AccSchema}
            onSubmit={({ resetForm }) => {
              setOpenSuccess(true);
              resetForm();
            }}
          >
            {({ errors, touched, values, handleSubmit, handleChange }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 my-3"
              >
                <LabelContainer title={"Contact"} id={1} />

                <CommonInput
                  value={values.fullName}
                  name={"fullName"}
                  placeholder={"Full Name*"}
                  onChange={(e) => handleChange(e)}
                  errors={errors.fullName}
                  touched={touched.fullName}
                />

                <CommonInput
                  value={values.phone}
                  name={"phone"}
                  placeholder={"Phone Number*"}
                  onChange={(e) => handleChange(e)}
                  errors={errors.phone}
                  touched={touched.phone}
                />

                <CommonInput
                  value={values.email}
                  name={"email"}
                  placeholder={"Email ID*"}
                  onChange={(e) => handleChange(e)}
                  errors={errors.email}
                  touched={touched.email}
                />

                <LabelContainer title={"Delivery details"} id={2} />

                <CommonInput
                  value={values.address1}
                  name={"address1"}
                  placeholder={"Address line 1*"}
                  onChange={(e) => handleChange(e)}
                  errors={errors.address1}
                  touched={touched.address1}
                />

                <CommonInput
                  value={values.address2}
                  name={"address2"}
                  placeholder={"Address line 2"}
                  onChange={(e) => handleChange(e)}
                  errors={errors.address2}
                  touched={touched.address2}
                />

                <div className="flex flex-row gap-5 justify-between">
                  <CommonInput
                    value={values.city}
                    name={"city"}
                    placeholder={"City"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.city}
                    touched={touched.city}
                  />

                  <CommonInput
                    value={values.state}
                    name={"state"}
                    placeholder={"State"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.state}
                    touched={touched.state}
                  />
                </div>

                <div className="flex flex-row gap-5 justify-between">
                  <CommonInput
                    value={values.country}
                    name={"country"}
                    placeholder={"Country*"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.country}
                    touched={touched.country}
                  />

                  <CommonInput
                    value={values.pinCode}
                    name={"pinCode"}
                    placeholder={"Pin code*"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.pinCode}
                    touched={touched.pinCode}
                  />
                </div>

                <div className="mt-[20px]">
                  <div className="flex justify-between">
                    <div className="text-[#83817e] text-[14px] xl:text-[24px] lg:text-[20px] md:text-[16px] sm:text-[14px] pb-[7px] sm:pb-0">
                      Subtotal
                    </div>
                    <div className="text-[#000] text-[14px] xl:text-[24px] lg:text-[20px] md:text-[16px] sm:text-[14px] pb-[7px] sm:pb-0">
                      ₹ 0
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-[#83817e] items-center text-[14px] xl:text-[20px] lg:text-[16px] md:text-[14px] sm:text-[12px]">
                      Shipping & taxes
                    </div>
                    <div className="text-[#000] text-[14px] xl:text-[24px] lg:text-[20px] md:text-[16px] sm:text-[14px] ">
                      ₹ 99
                    </div>
                  </div>
                  <div className="flex justify-between mt-[8px]">
                    <div className="text-[#000] font-semibold text-[14px] xl:text-[28px] lg:text-[24px] md:text-[18px] sm:text-[16px]">
                      Total
                    </div>
                    <div className="text-[#000] font-semibold text-[14px] xl:text-[28px] lg:text-[24px] md:text-[18px] sm:text-[16px]">
                      ₹ 99
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="border-2 cursor-pointer border-black py-2 px-[5px] lg:px-[10px] md:px-[8px] sm:px-[6px] phone:px-[4px] my-[1rem] mx-[1rem] lg:mx-[5rem] md:mx-[4rem] sm:mx-[3rem] phone:mx-[5px] rounded-full flex justify-center items-center bg-[#FAA51A]"
                >
                  <p className="text-[14px] lg:text-[20px] md:text-[18px] phone:text-[16px] font-semibold">
                    PAY NOW
                  </p>
                </button>
              </form>
            )}
          </Formik>

          <p className="mx-auto lg:mx-[5rem] md:mx-[4rem] sm:mx-[3rem] phone:mx-[2rem]">
            After clicking “Pay now”, you will be redirected to PhonePe Payment
            gateway (UPI, Cards, Wallets, NetBanking) to complete your purchase
            securely.
          </p>

          <div className="underline gap-5 my-5 lg:mx-[5rem] md:mx-[4rem] sm:mx-[3rem] phone:mx-[2rem] grid grid-cols-2">
            <p onClick={handleTerms} className="cursor-pointer">
              Terms and conditions
            </p>
            <p onClick={handleShipping} className="cursor-pointer">
              Shipping policy
            </p>
            <p onClick={handlePrivacy} className="cursor-pointer">
              Privacy policy
            </p>
            <p onClick={handleReturn} className="cursor-pointer">
              Return policy
            </p>
          </div>

          <FooterModal
            open={open}
            categoryFlag={categoryFlag}
            setOpen={setOpen}
          />
          <CheckOutModal
            openModal={openSuccess}
            setOpenModal={setOpenSuccess}
          />
        </div>
      </div>
    </header>
  );
}
