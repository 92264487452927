// Library imports
import React from "react";
import { RefundsReturn } from "../../apis/constants";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function ReturnPolicy({ setIsHeaderShow, setIsPreOrderHeader }) {
  setIsHeaderShow(false);
  setIsPreOrderHeader(false);
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate("/");
  };

  const CommonSummary = ({ title, description, extraTitle }) => {
    return (
      <>
        <p className="font-semibold my-1">
          {title} <span className="font-normal">{description}</span>
        </p>
        {extraTitle && (
          <p className="font-bold text-[18px] my-[10px]">{extraTitle}</p>
        )}
      </>
    );
  };

  const CommonMegaSummary = ({ title, description }) => {
    return (
      <div className="my-2">
        <p className="font-semibold">{title}</p>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <>
      <div className="bg-[#EFEAE4] fontStyle flex p-5 justify-between items-center sticky top-0">
        <p className="text-[24px] flex flex-row items-center gap-8">
          <IoMdArrowBack size={25} cursor={"pointer"} onClick={onClickBack} />
          Returns, Refunds, Cancellation and Order Update
        </p>
      </div>

      <div className="fontStyle px-[20px]">
        <div className="text-[16px] p-[20px] lg:p-[20px] md:p-[18px] sm:p-[16px] phone:p-[14px] flex flex-col gap-3">
          <p>
            At Opod Play, we want to make sure you love your order, and because
            of the product quality/damage, print quality/damage, or any other
            reasons that you’re not satisfied with, we’re here to resolve it for
            you.
          </p>

          <p>
            For any quality issues, damage, or wrong print, be sure to provide
            clear photos of the products on a flat, well-lit surface. This quick
            and simple step will help us to provide a speedy resolution. Here
            we’ve mentioned the cancellation, return, and order update policy.
            Please note that this policy is an ever-evolving process and if
            there’s something that you need help with but you can’t find it
            within the policy, write to us at hello@opod.in with your issue and
            your contact number, and we’ll get in touch with you.
          </p>

          <div className="my-1">
            <p className="font-semibold">
              Best Practices For Writing A Support Email to Opod Play
            </p>
            <p>
              As of now, Email (hello@opod.in) is the official channel for
              customer support. Here are the best practices to follow when
              writing a support email to Opod Play.
            </p>
          </div>
          <div className="mx-4">
            <li>
              Use the email that you used to place your order on the Opod Play
              store.
            </li>
            <li>Mention “Support – {"{Reason}"}” within the subject line.</li>
            For example: “Support – {"{Product Name}"} Damaged”.
            <li>
              Please provide photos of the product if the issue is of quality,
              damage, or wrong print.
            </li>
            <li>
              Include your contact number on which you are easily reachable.
            </li>
            <li>Share screenshots of the order tracking page (if possible).</li>
          </div>

          <CommonMegaSummary
            title={"Return"}
            description={
              "All orders on Opod Play are printed to ship. If a cancellation is applicable after delivery (damage quality issues, wrong item), we’ll refund the amount. All other cases are mentioned in the policy below. Note: The return is only available if there’s an issue with the game design or print quality or if we deliver the wrong product. Otherwise, orders are not eligible for return/exchange."
            }
          />
          
          <p>
            Please write to us with your account email at{" "}
            <a href="mailto:hello@opod.in" className="text-[#2E86C1]">hello@opod.in</a>, and mention your
            order details along with the issue faced. Once the issue is
            acknowledged by our team, the damaged/ incorrect item, should be
            returned to the official address within 7 days from the delivery
            date. Else the return request would be considered void.
          </p>

          <div className="my-2">
              <p className="font-semibold">Cancellations</p>
              <p>
                If you wish to cancel, please write to us with your account
                email at <a href="mailto:hello@opod.in" className="text-[#2E86C1]">
                hello@opod.in
              </a> and mention your order details. Order
                once placed can only be canceled within 12 hrs from the time of
                ordering. You can cancel your order at any time before it goes
                to production. Once your order has gone to production, it can’t
                be canceled mid-way. Post successful cancellation, we’ll refund
                the partial/full amount as per the resolution.
              </p>
            </div>


          <CommonMegaSummary
            title={"Damaged/Quality Issues"}
            description={
              "For the fastest resolution, please include a photograph demonstrating the poor quality of the print or the damaged area of the purchased product. The most optimal pictures are on a flat surface with the error displayed. We will use this information to help you with your order, as well as eliminate errors in future printing."
            }
          />

          <p className="font-bold text-[18px]">Order Update / Amendments</p>

          <CommonSummary
            title={"Adding new products to the placed order:"}
            description={
              " If you forget to order the right quantity of the products or miss adding some products while making a purchase. You can simply go ahead and place another order for the same delivery address. If we get the amended order on the same day, then we’ll batch them and deliver them together. Please be assured that all your placed orders will get delivered."
            }
          />

          <CommonSummary
            title={"Removing products from the placed order:"}
            description={
              "Once you place an order, you cannot change the order quantity or remove orders from the cart."
            }
          />

          <CommonSummary
            title={"Updating shipping/delivery address and details:"}
            description={
              "The shipping/delivery address and details on the order can only be updated until the order is not processed. After the production, the shipping labels get printed and the products already get packaged in them. You can write to us at hello@opod.in with the correct delivery address and details and we’ll make changes to the shipping address accordingly."
            }
          />

          <CommonMegaSummary
            title={"Refunds"}
            description={
              "Once the resolution is done and Opod Play needs to refund the order value partially or completely, we’ll process the refund. Based on your payment method, the refund time duration may vary. We do not have control over banking processes and in edge cases, these things may fail or get delayed. We’ll do our best to ensure that your refund is deposited back into your bank account within 7 days."
            }
          />

          {RefundsReturn.map((item, index) => (
            <CommonSummary
              key={index}
              title={item.title}
              description={item.description}
              extraTitle={item.extraTitle}
            />
          ))}

          <p>
            It is Opod Play’s sole responsibility to assist the buyers/sellers
            with all kinds of issue resolutions and support. We suggest that you
            do not send your support email or complain to the creators/sellers.
            As it is not in their control to provide support on the operations
            and delivery process.
          </p>

          <p className="font-bold my-2">
            We’ll do our best to ensure that your refund is deposited back into
            your bank account within 7 days.
          </p>

        </div>
      </div>
    </>
  );
}
