// Library imports
import React, { forwardRef, useState } from "react";
import "./footer.css";
import { FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaRegCopyright } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// Local imports
import FooterModal from "../Modals/FooterModal";

export const Footer = forwardRef(({ id }, ref) => {
  const isTablet = useMediaQuery({ maxWidth: "768px" });
  const [open, setOpen] = useState(false);
  const [categoryFlag, setCategoryFlag] = useState(null);
  const navigate = useNavigate();

  const CommonCategories = ({ name, onClick, isRefund }) => {
    return (
      <div onClick={onClick}>
        <p
          class={`md:font-semibold ${
            isRefund && "phone:ml-[10px] ml-0"
          } text-[16px] md:text-[20px] hover:cursor-pointer max-md:underline`}
        >
          {name}
        </p>
      </div>
    );
  };

  const handleTerms = () => {
    navigate("/terms-and-conditions");
  };

  const handleShipping = () => {
    navigate("/shipping-policy");
  };

  const handlePrivacy = () => {
    navigate("/privacy-policy");
  };

  const handleReturn = () => {
    navigate("/return-policy");
  };

  // const onClickAbout = () => {
  //   const aboutSection = document.getElementById("about");
  //   if (aboutSection) {
  //     aboutSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <div id={id} ref={ref} className="fontStyle">
      <footer class="text-[#F2F5EA] footerBackground">
        <div class="flex max-md:flex-col px-[24px] md:px-20 py-7 text-left justify-between">
          <div class="flex flex-row items-end lg:w-[35%]">
            <nav class="list-none">
              <p class="footerMainTitle">Connect with us:</p>
              <div className="flex flex-row justify-start my-3 gap-4">
                <Link target="_blank" to={"https://www.linkedin.com/company/opod-play/"}>
                  <FaLinkedin size={32} color="#EFEAE5" />
                </Link>

                <Link target="_blank" to={"https://www.instagram.com/opod_play/"}>
                  <RiInstagramFill size={32} color="#EFEAE5" />
                </Link>
              </div>

              <div className="font-semibold text-[20px]">
                <Link to={"mailto:hello@opod.in"}>
                  <p>Email: hello@opod.in</p>
                </Link>
                <div className="grid md:grid-cols-2 grid-cols-1">
                  <Link to={"tel:+91-7977068818"}>
                    <p>Phone: +91-7977068818</p>
                  </Link>
                </div>
              </div>
            </nav>
          </div>

          <div className="max-dm:hidden flex-row lg:flex hidden items-end justify-center lg:w-[30%]">
            <div className="flex flex-row text-center items-center gap-1 justify-center">
              <FaRegCopyright size={20} />
              <p className="opodPlayStyle text-[16px] font-normal">2024, HWO MEDIA PVT. LTD.</p>
            </div>
          </div>

          <div className="lg:w-[35%] justify-between">
            <div class="max-md:hidden flex flex-col justify-between text-end pt-[10px] h-full">
              {/* <CommonCategories name="About" onClick={onClickAbout} /> */}
              <CommonCategories name="Terms and conditions" onClick={handleTerms} />
              <CommonCategories name="Shipping policy" onClick={handleShipping} />
              <CommonCategories name="Privacy policy" onClick={handlePrivacy} />
              <CommonCategories name="Return policy" onClick={handleReturn} />
            </div>

            <div className="md:hidden flex flex-col justify-between space-y-5 ml-4 mt-8 h-full">
              <div className="flex gap-10">
                <CommonCategories name="Shipping Policy" onClick={handleShipping} />
                <CommonCategories name="Terms and conditions" onClick={handleTerms} />
              </div>
              <div className="flex gap-10">
                <CommonCategories name="Privacy policy" onClick={handlePrivacy} />
                <CommonCategories isRefund={true} name="Return policy" onClick={handleReturn} />
              </div>
            </div>
          </div>

          <FooterModal open={open} categoryFlag={categoryFlag} setOpen={setOpen} />
        </div>
      </footer>
    </div>
  );
});
